import { useEffect, useState, useRef } from "react";
import { view, autoEffect } from "@risingstack/react-easy-state";
import { Outlet, useParams, useNavigate, NavLink } from "react-router-dom";

import { devices } from "../../stores/devices";

import Button from "../../parts/Button/Button";
import MeasurementReportModal from "./MeasurementReportModal";
import MeasurementReportSuccessModal from "./MeasurementReportSuccessModal";
import OnlineBill from "./OnlineBill";

import { Box, Tooltip, Popper, ClickAwayListener } from "@mui/material";

import {
  MeasurementReportNavSC,
  IconCalendarSC,
  IconRocketSC,
  YearButtonSC,
  YearListSC,
  YearNavigationSC,
  YearMenuSC,
  YearMenuItemSC,
} from "./styled";
import NoData from "../../parts/PageMessage/NoData";

const DeviceDetails = view(() => {
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const { year } = useParams();
  const calendarRef = useRef(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [archiveYear, setArchiveYear] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalSuccessIsOpen, setModalSuccessIsOpen] = useState(false);

  autoEffect(() => {
    devices.device = null;
    if (devices.allDevices?.length > 0) {
      devices.device = devices.allDevices.find(
        (dev) => dev.measuring_place_code === deviceId
      );
      if (devices.device && devices.yearlyBills?.length > 0) {
        if (!year) {
          let y0 = devices.yearlyBills[0].bills[0].reading_date.substring(0, 4);
          navigate(`/vodomjeri/${deviceId}/${y0}`);
          setCalendarOpen(false);
          setArchiveYear(null);
        } else {
          // set archive year
          devices.yearlyBills.findIndex((b, index) => {
            if (
              index > 1 &&
              b.bills[0]?.reading_date.substring(0, 4) === year
            ) {
              setArchiveYear(year);
              return true;
            }
            return false;
          });
        }
      }
      if (!devices.device) {
        navigate("/");
      }
    }
  }, [deviceId]);

  useEffect(() => {
    return () => {
      devices.device = null;
    };
    // eslint-disable-next-line
  }, []);

  const handleClickCalendar = () => {
    setCalendarOpen((prev) => !prev);
  };

  const handlePickCalendarYear = (e, year) => {
    if (calendarRef.current?.contains(e.target)) {
      return;
    }
    setArchiveYear(year);
    setCalendarOpen(false);
  };

  return (
    <>
      {devices.device?.measuring_place_code === deviceId && (
        <>
          <MeasurementReportNavSC>
            {devices.device.measurable ? (
              <Button
                colbg="primary500"
                coltext="neutral100"
                onClick={() => setModalIsOpen(true)}
                endIcon={<IconRocketSC />}
              >
                Dostava očitanja
              </Button>
            ) : (
              <Tooltip
                title="Dostava očitanja trenutno nije dostupna"
                placement="bottom"
                arrow
              >
                <span>
                  <Button
                    disabled
                    colbg="primary500"
                    coltext="neutral100"
                    endIcon={<IconRocketSC />}
                  >
                    Dostava očitanja
                  </Button>
                </span>
              </Tooltip>
            )}
          </MeasurementReportNavSC>
          {devices.yearlyBills?.length > 0 && (
            <YearNavigationSC>
              {devices.yearlyBills.length > 2 ? (
                <ClickAwayListener onClickAway={() => setCalendarOpen(false)}>
                  <Box sx={{ height: "20px" }}>
                    <Tooltip title="Arhiva" placement="top" arrow>
                      <IconCalendarSC
                        className="clickable"
                        ref={calendarRef}
                        onClick={handleClickCalendar}
                      />
                    </Tooltip>

                    {calendarOpen && (
                      <Popper
                        open={calendarOpen}
                        anchorEl={calendarRef.current}
                        placement="bottom"
                      >
                        <YearMenuSC>
                          <span className="box-tip"></span>
                          {devices.yearlyBills.map((yb, index) => {
                            let yearExtract =
                              yb.bills[0].reading_date.substring(0, 4);
                            if (index > 1) {
                              return (
                                <YearMenuItemSC
                                  onClick={(e) =>
                                    handlePickCalendarYear(e, yearExtract)
                                  }
                                  component={NavLink}
                                  to={`/vodomjeri/${deviceId}/${yearExtract}`}
                                  key={yearExtract}
                                >
                                  {yearExtract}.
                                </YearMenuItemSC>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </YearMenuSC>
                      </Popper>
                    )}
                  </Box>
                </ClickAwayListener>
              ) : (
                <IconCalendarSC />
              )}

              <YearListSC>
                {devices.yearlyBills.map((yb, index) => {
                  let yearExtract = yb.bills[0].reading_date.substring(0, 4);
                  if (index < 2) {
                    return (
                      <YearButtonSC
                        onClick={() => setArchiveYear(null)}
                        selected={yearExtract === year}
                        component={NavLink}
                        to={`/vodomjeri/${deviceId}/${yearExtract}`}
                        key={yearExtract}
                      >
                        {yearExtract}.
                      </YearButtonSC>
                    );
                  } else {
                    return null;
                  }
                })}
                {archiveYear && year && (
                  <YearButtonSC
                    selected={archiveYear === year}
                    component={NavLink}
                    to={`/vodomjeri/${deviceId}/${year}`}
                  >
                    {year}.
                  </YearButtonSC>
                )}
              </YearListSC>
            </YearNavigationSC>
          )}
          {devices.device.id && (
            <MeasurementReportModal
              deviceId={devices.device.id}
              lastMeasurement={
                devices.yearlyBills
                  ? devices.yearlyBills[0].bills[
                      devices.yearlyBills[0].bills.length - 1
                    ].water_gauge
                  : null
              }
              averageMonthlyConsumption={
                devices.yearlyBills
                  ? devices.yearlyBills[0].average_monthly_consumption
                  : null
              }
              lastConsumption={
                devices.yearlyBills
                  ? devices.yearlyBills[0].bills[
                      devices.yearlyBills[0].bills.length - 1
                    ].bill_consumption
                  : null
              }
              lastReadingDate={
                devices.yearlyBills
                  ? devices.yearlyBills[0].bills[
                      devices.yearlyBills[0].bills.length - 1
                    ].reading_date
                  : null
              }
              measuringPlaceCode={devices.device.measuring_place_code}
              address={
                devices.device.Street.name + " " + devices.device.street_number
              }
              open={modalIsOpen}
              setIsOpen={setModalIsOpen}
              setSuccessIsOpen={setModalSuccessIsOpen}
            />
          )}

          <MeasurementReportSuccessModal
            open={modalSuccessIsOpen}
            setIsOpen={setModalSuccessIsOpen}
          />

          {/* Year Details */}
          <Outlet />

          <OnlineBill />

          {!devices.yearlyBills && (
            <NoData
              variant="relative"
              message="Trenutno nema podataka za odabrano mjerno mjesto."
            />
          )}
        </>
      )}
    </>
  );
});

export default DeviceDetails;
