import { useState, useEffect } from "react";
import { view } from "@risingstack/react-easy-state";
import { format } from "date-fns";

import { measurements } from "../../stores/measurements";

import Modal from "../../parts/Modal/Modal";
import Button from "../../parts/Button/Button";

import { FormGroupsSC, TextErrorSC } from "../../parts/styled";
import {
  LastConsumptionWrapperSC,
  TextLightSC,
  TextBoldSC,
  TextLastConsumptionSC,
  ButtonsWrapperSC,
  SubsectionSC,
} from "./styled";

const MeasurementReportModal = view(
  ({
    deviceId,
    averageMonthlyConsumption,
    lastMeasurement,
    lastConsumption,
    lastReadingDate,
    measuringPlaceCode,
    address,
    open,
    setIsOpen,
    setSuccessIsOpen,
  }) => {
    const [modalState, setModalState] = useState("input");
    const [inputValue, setInputValue] = useState("");
    const [errorInput, setErrorInput] = useState("");
    const [data, setData] = useState(null);

    useEffect(() => {
      setData({
        deviceId: deviceId,
        averageMonthlyConsumption: averageMonthlyConsumption,
        lastMeasurement: lastMeasurement,
        lastConsumption: parseFloat(lastConsumption),
        lastReadingDate: lastReadingDate,
        measuringPlaceCode: measuringPlaceCode,
        address: address,
      });
      // eslint-disable-next-line
    }, [deviceId]);

    const preventNonNumericalInput = (e) => {
      e = e || window.event;
      var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
      var charStr = String.fromCharCode(charCode);
      if (!charStr.match(/^[0-9]+$/)) {
        e.preventDefault();
      }
    };

    const handleInputChange = (e) => {
      setErrorInput("");
      setInputValue(e.target.value);
    };

    const handleCancel = () => {
      setModalState("input");
      setInputValue("");
      setErrorInput("");
      setIsOpen(false);
    };

    const handleConfirmInput = (e) => {
      e.preventDefault();
      if (parseInt(inputValue) >= data.lastMeasurement + 100) {
        setErrorInput(
          "Provjerite vodomjer još jednom, potrošnja je veća za više od 100m2 od prošlog očitanja."
        );
      } else if (
        parseInt(inputValue) - data.averageMonthlyConsumption >=
        data.averageMonthlyConsumption * 1.3
      ) {
        setModalState("30percent");
      } else {
        setModalState("confirmation");
      }
    };

    const handleSubmit = async () => {
      await measurements.postMeasurement(data.deviceId, inputValue);
      if (measurements.isPostSuccessfull) {
        setInputValue("");
        setModalState("input");
        setErrorInput("");
        setIsOpen(false);
        setSuccessIsOpen(true);
        measurements.isPostSuccessfull = false;
      }
    };

    return (
      <Modal title="Dostava očitanja" open={open}>
        {modalState === "input" && data ? (
          <>
            <LastConsumptionWrapperSC>
              {data.lastMeasurement ? (
                <>
                  <TextLightSC>
                    Stanje brojila dana{" "}
                    {data.lastReadingDate &&
                      format(new Date(data.lastReadingDate), "d.M.yyyy")}{" "}
                    iznosilo je:
                  </TextLightSC>
                  <TextLastConsumptionSC>
                    {data.lastMeasurement}
                  </TextLastConsumptionSC>
                </>
              ) : (
                <TextLightSC>Nema podataka o prošlom mjerenju</TextLightSC>
              )}
            </LastConsumptionWrapperSC>
            <form style={{ width: "100%" }}>
              <FormGroupsSC>
                <div className={errorInput ? "form-group error" : "form-group"}>
                  <div>
                    <input
                      type="number"
                      min={data.lastMeasurement}
                      id="measurement-report"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={preventNonNumericalInput}
                    />
                    <label htmlFor="measurement-report">
                      Unesite novo stanje brojila
                    </label>
                  </div>
                  <TextErrorSC>{errorInput}</TextErrorSC>
                </div>
              </FormGroupsSC>
              <ButtonsWrapperSC>
                <Button
                  colbg="primary500"
                  coltext="primary500"
                  outlined="true"
                  onClick={handleCancel}
                >
                  Odustani
                </Button>
                <Button
                  colbg="primary500"
                  coltext="neutral100"
                  onClick={handleConfirmInput}
                  disabled={!inputValue}
                >
                  Pošalji
                </Button>
              </ButtonsWrapperSC>
            </form>
          </>
        ) : modalState === "30percent" && data ? (
          <>
            <SubsectionSC>
              <TextBoldSC>
                Trenutno mjerenje je veće za 30% od prosjeka.
              </TextBoldSC>
            </SubsectionSC>
            <ButtonsWrapperSC>
              <Button
                colbg="primary500"
                coltext="primary500"
                outlined="true"
                onClick={() => setModalState("input")}
              >
                Izmijeni
              </Button>
              <Button
                colbg="primary500"
                coltext="neutral100"
                onClick={() => setModalState("confirmation")}
              >
                Nastavi
              </Button>
            </ButtonsWrapperSC>
          </>
        ) : (
          modalState === "confirmation" &&
          data && (
            <>
              <SubsectionSC>
                <TextBoldSC>Adresa mjesta potrošnje</TextBoldSC>
                <TextLightSC>{data.address}</TextLightSC>
              </SubsectionSC>
              <SubsectionSC>
                <TextBoldSC>Broj vodomjera</TextBoldSC>
                <TextLightSC>{data.deviceId}</TextLightSC>
              </SubsectionSC>
              <SubsectionSC sx={{ marginBottom: "40px" }}>
                <TextBoldSC>Stanje vodomjera</TextBoldSC>
                <TextLightSC>{inputValue}</TextLightSC>
              </SubsectionSC>
              <ButtonsWrapperSC>
                <Button
                  colbg="primary500"
                  coltext="primary500"
                  outlined="true"
                  onClick={() => setModalState("input")}
                >
                  Izmijeni
                </Button>
                <Button
                  colbg="primary500"
                  coltext="neutral100"
                  onClick={handleSubmit}
                >
                  Pošalji
                </Button>
              </ButtonsWrapperSC>
            </>
          )
        )}
      </Modal>
    );
  }
);

export default MeasurementReportModal;
